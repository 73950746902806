<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color: gray">
              <b>{{ title }}</b>
            </h4>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="بحث"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :search="search"
              :loading="loading"
              :headers="headers"
              :items="tasks"
              sort-by="name"
              class="elevation-1 mt-3"
            >
              <template v-slot:top>
                <v-dialog v-model="dialogDelete" max-width="300px">
                  <v-card>
                    <v-card-title class="headline"
                      >هل أنت متأكد من الحذف؟</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text-color="white"
                        text
                        @click="closeDelete"
                        >إلغاء</v-btn
                      >
                      <v-btn
                        color="gray"
                        text-color="white"
                        text
                        @click="deleteItemConfirm"
                        >موافق</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:[`item.typeId`]="{ item }">
                {{
                  item.typeId == 1
                    ? "زيارة"
                    : item.typeId == 2
                    ? "تنبيه"
                    : item.typeId == 3
                    ? "طلب معلومات "
                    : "-"
                }}
              </template>
              <template v-slot:[`item.startDate`]="{ item }">
                {{ createdDate(item.startDate) }}
              </template>
              <template v-slot:[`item.endDate`]="{ item }">
                {{ createdDate(item.endDate) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="green"
                  text-color="white"
                  small
                  class="mr-2"
                  @click="
                    $router.push({
                      name: 'show-task',
                      params: { task: item, taskId: item.idtask }
                    })
                  "
                >
                  mdi-eye
                </v-icon>
                <v-icon
                  color="blue"
                  v-if="role == 'Admin'"
                  text-color="white"
                  small
                  class="mr-2"
                  @click="
                    $router.push({
                      name: 'edit-task',
                      params: { task: item, taskId: item.idtask }
                    })
                  "
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="red"
                  v-if="role == 'Admin'"
                  text-color="white"
                  small
                  @click="deleteItem(item.idtask)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data> لا توجد بيانات </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$router.go(-1)" style="background: gray" dark>
              <b>رجوع</b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      EndDateRules: [
        v =>
          v > this.filters.startDate ||
          "تاريخ النهاية لا يمكن أن يسبق  تاريخ البداية"
      ],
      dialogDelete: false,
      headers: [
        {
          text: "رقم المهمة",
          align: "center",
          sortable: true,
          value: "idtask"
        },
        {
          text: "اسم المهمة",
          align: "center",
          sortable: true,
          value: "name"
        },
        {
          text: "نوع المهمة",
          align: "center",
          sortable: true,
          value: "typeId"
        },
        {
          text: "تاريخ البداية",
          align: "center",
          sortable: true,
          value: "startDate"
        },
        {
          text: "تاريخ النهاية",
          align: "center",
          sortable: false,
          value: "endDate"
        },
        { text: "خيارات", align: "center", value: "actions", sortable: false }
      ],
      title: "",
      StartDate: "",
      endDate: "",
      loading: false,
      tasks: [],
      externalType: "",
      page: 1,
      totalItems: 0,
      type: 1,
      dialog: false,
      taskDelete: 0
    };
  },
  methods: {
    deleteItem(item) {
      this.deletedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.ApiService.delete("task/DeleteTask/" + this.deletedItem)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.getAllTasks();
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.deletedItem = null;
      this.dialogDelete = false;
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
    async getAllTasks() {
      let url = null;
      if (this.$route.params.type == "general-tasks") {
        url = "task/GetAllTasks";
        this.title = "كل المهام";
      }
      if (this.$route.params.type == "new-tasks") {
        url = "task/NewTasksByUser?user=" + this.user.id;
        this.title = "كل المهام الموكلة";
      }
      if (this.$route.params.type == "archive-tasks") {
        url = "task/ArchiveTasksByUser?user=" + this.user.id;
        this.title = "كل المهام المنجزة";
      }
      if (this.$route.params.type == "waiting-review-tasks") {
        url = "Task/WaitingTasksByUser?user=" + this.user.id;
        this.title = "كل المهام بانتظار المراجعة";
      }
      if (this.$route.params.type == "doing-task-tasks") {
        url = "task/DoingTasksByUser?user=" + this.user.id;
        this.title = "كل المهام قيد التقدم";
      }
      this.loading = true;
      await this.ApiService.get(url)
        .then(res => {
          this.tasks = [];
          this.tasks = res.data.responseData;
          if (this.$route.params.type == "general-tasks") {
            let data = [];
            this.tasks.forEach(el => {
              if (el.typeId != 1 && el != null) data.push(el);
            });
            this.tasks = data;
          }
        })
        .catch(() => {});
      this.loading = false;
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getAllTasks();
  }
};
</script>
<style lang="scss"></style>
